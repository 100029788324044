/** @format */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCZJEDBrjmg5gd8F_RbllJ_Hpi_thTwH2M',
    authDomain: 'taekwonbaro.firebaseapp.com',
    projectId: 'taekwonbaro',
    storageBucket: 'taekwonbaro.appspot.com',
    messagingSenderId: '260311894335',
    appId: '1:260311894335:web:3a98e09c72257caf1b2b8a',
    measurementId: 'G-LWNZXW3J5Y',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
